import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
} from '@/constants/index'
import { isBeta } from '@/utils/helpers'

import {
  doInternalRequest,
  getFromApi,
} from './'

export const getProperties = async (isHighlight?: boolean) => {
  return await getFromApi(`/propeasy/property?env=${isBeta ? 'beta' : 'prod'}${isHighlight ? '&is_highlight=true' : ''}`)
}

export const fetchBurnTransaction = async (tokenName: string, page?: number, perPage?: number) => {
  const pageParam = page ?? DEFAULT_PAGE
  const perPageParam = perPage ?? DEFAULT_PER_PAGE

  return await getFromApi(`/propeasy/property/${tokenName}/burn_transactions?page=${pageParam}&per=${perPageParam}`)
}

export const getTransactionsByBuyerAddress = async (
  buyer_address: string,
  search?: string,
  sale_type?: string,
) => {
  return await getFromApi(
    `/propeasy/property_transactions?buyer_address=${buyer_address}&sale_type=${
      sale_type || ''
    }&search=${search || ''}&env=${isBeta ? 'beta' : 'prod'}`,
  )
}

export const confirmLatestBuyer = async (tokenName: string, saleType: string) => {
  return await doInternalRequest(
    `/propeasy/property/${tokenName}/confirm_latest_buyer/${saleType}`,
    'put',
  )
}

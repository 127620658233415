import { memo, useEffect } from 'react'
import { Stack } from '@mui/material'

import Introduction from './components/introduction'
import Information from './components/information'
import Profits from './components/profits'
import TokenInfo from './components/token-info'
import BlogList from './components/blog-list'
import { track } from '@/utils/amplitude'

const View = () => {
  useEffect(() => {
    track('visit_home_page')
  }, [])

  return (
    <Stack gap={8}>
      <Stack>
        <Introduction />
        <Information />
      </Stack>
      <Profits />
      <TokenInfo />
      <BlogList />
    </Stack>
  )
}

const HomeWithMemo = memo(View)
HomeWithMemo.displayName = 'HomeView'
export default HomeWithMemo

import { DATE_MONTH } from '@/constants/index'
import { timeFormatString } from '@/utils/helpers'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  header: {
    vietnamese: 'Vietnamese',
    english: 'English',
  },
  footer: {
    description:
      'Propeasy converts estates into tokens on the blockchain, helping investors own shares of assets with small capital and trade easily.',
    company: 'Company',
    company_about: 'About us',
    company_terms: 'Terms of service',
    company_policy: ' Privacy policy',
    company_contact: 'Contact & Support',
    resource: 'Resources',
    resource_document: 'Documents',
    resource_news: 'News',
    resource_blog: 'Blog',
    social_follow_us: 'Follow us on',
  },
  subscribe: {
    newsletters: 'Subscribe',
    description: 'Get the latest updates about our real estate projects currently open for sale.',
    email: 'Email',
    register: 'Subscribe',
    success: 'Subscribed email successfully',
    confirm_title: 'Signup verification needed',
    confirm_description:
      'We sent a verification link to your email. Please check your email and follow the instructions to complete signin verification.',
    confirm_done: 'Understood',
    confirm_warning: 'Please enter the correct email format',
    confirm_error: 'An error occurred, please try again later',
  },
  home: {
    coming_soon: 'Sắp ra mắt',
    introduction: {
      hero_title:
        'Real estate 4.0<br/>Start investing <span class="text-ps-secondary">from just 50 cents</span>',
      hero_description:
        'Easily own and trade tokens backed by real estates that are <span class="font-bold text-ps-secondary">evaluated and supervised by Renec Foundation</span> through blockchain technology.',
      hero_view_properties: 'View properties',
      hero_view_introduction: 'Watch video',
      featured_in: 'featured in',
      audited_by: 'audited by',
    },
    question_answer: {
      title: 'Câu hỏi thường gặp',
      have_any_question: 'Bạn vẫn còn thắc mắc?',
      document: 'Xem tài liệu',
      details: [
        {
          question:
            'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản?',
          answer: 'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản',
        },
        {
          question:
            'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản?',
          answer: 'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản',
        },
        {
          question:
            'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản?',
          answer: 'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản',
        },
        {
          question:
            'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản?',
          answer: 'Tôi có được những lợi ích gì khi nắm giữ mã tiền điện tử của một bất động sản',
        },
      ],
    },
    information: {
      title: 'Ecosystem of tokenized real estates',
      description:
        'Diversify your portfolio with tokenized and fractional real estates, thereby increasing liquidity, transparency and accessibility.',
      card_1: {
        title: 'Flexible Investment Capital',
        description:
          'Buy tokens representing shares of a real estate from just 50 cents, instead of buying the entire asset at high cost in the traditional way.',
      },
      card_2: {
        title: 'Safe Growth',
        description:
          "Real estates can potentially generate steady business revenue and sustainable long-term appreciation, protecting investors' assets as their tokens increase in value accordingly.",
      },
      card_3: {
        title: '24/7 Liquidity',
        description:
          'Buy and sell tokens at any time on exchanges with good liquidity, helping investors access capital immediately when needed.',
      },
      card_4: {
        title: 'Trusted Origin of Real Estates',
        description:
          "Each real estate has information verified, value appraised and carefully monitored by Renec Foundation to protect investors' interests.",
      },
      card_5: {
        title: 'Referral Commissions',
        description:
          'Unlimited passive income from commissions allocated to both referrer and referee.',
      },
    },
    profits: {
      title: 'Real assets, real returns',
      description:
        'Holding real-estate tokens allows investors to access real estates tokenized on the blockchain and profit accordingly as those assets generate returns - simple as that.',
      card_1: {
        title: 'Tokenization of assets',
        description:
          'Real estates are <span class="text-ps-secondary">verified and evaluated by Renec Foundation</span> before being tokenized onto the blockchain to issue real-estate tokens. Each real-estate token represents a share of the corresponding real estate.',
      },
      card_2: {
        title: 'Generate returns',
        description:
          'Real estates are professionally supervised to comply with the original business purpose, producing stable returns and cash flow while increasing in value over time.',
      },
      card_3: {
        title: 'Hold and profit',
        description:
          'When real estates generate business profits and increase in value, Propeasy proceeds to buy back and burn the corresponding real-estate tokens to increase value for investors.',
      },
    },
    token_info: {
      title: 'Tokens in the ecosystem',
      buy_now: 'Buy now',
      learn_more: 'Learn more',
      price: 'Price',
      market_cap: 'Market cap',
      circulating: 'Circulating',
      view_all: 'View all',
      sale_date: 'Opening date',
      prop: {
        symbol: 'PROP',
        name: 'Native token',
        description:
          'PROP is the native token of Propeasy platform with utilities such as participation privilege in private sales of real-estate tokens, payment, trading, DAO governance, and more.',
      },
      gast: {
        symbol: 'GAST',
        name: 'Real-estate token',
        description:
          'GAST is a real-estate token backed by G6000 Gas Station and Automative Showroom Complex real estate. Buy and hold to earn passive income from real property rental income.',
      },
      plus1: {
        symbol: 'PLUS1',
        name: 'Real-estate token',
        description:
          'PLUS1 is a real-estate token backed by LP0001 Luxury Apartment Landmark Plus - Vinhomes Central Park real estate. Buy and hold to earn passive income from real property rental income.',
      },
      unit: {
        million: 'M',
        billion: 'B',
      },
    },
    featured_list: {
      title: 'Featured listing',
      description:
        'Explore our exclusive properties for diverse investment opportunities and profit potential for you.',
      view_all: 'View all',
    },
    blog_list: {
      title: 'Recent Announcements',
      view_all: 'View all',
    },
  },
  common: {
    partner_of: 'Partnered with',
    powered_by: '© 2023 . Propeasy Labs',
    home: 'Home',
    search: 'Search...',
    login: 'Sign in',
    logout: 'Sign out',
    status: {
      all: 'All',
      on_sale: 'On sale now',
      comming_soon: 'Upcoming',
      closed: 'Ended',
      sold_out: 'Sold out',
      burning: (startDate: string, endDate: string) =>
        `Burn tokens from ${timeFormatString(startDate, DATE_MONTH)} to ${timeFormatString(
          endDate,
          DATE_MONTH,
        )}`,
      burnFilterLabel: 'Burning tokens',
    },
    verifying: 'Checking validation...',
    captcha: {
      anchor: 'I am human',
      challenge: 'Slide to finish the puzzle',
    },
    back: 'Back',
    close: 'Close',
    share: 'Share',
    connect_wallet: 'Connect wallet',
    no_data: 'No data found',
    expand_more: 'See more',
    expand_less: 'See less',
    copied: 'Copied',
    verified: 'Verified',
    detail: 'Detail',
  },
  meta: {
    home: {
      title: 'Propeasy | Official Website',
      description:
        'Real estate 4.0 based on Blockchain technologies. Start investing from just 50 cents',
      thumb: '/thumb-home-propeasy-en.png',
    },
    blogs: {
      title: 'Propeasy | Blog',
      description:
        'All the latest information about investment in real estate based on blockchain technology',
      thumb: '/thumb-home-propeasy-en.png',
    },
    properties: {
      title: 'Propeasy | Properties',
      description:
        'List of real estate tokens for you to invest and profit from. Safeness secured by blockchain technology',
      thumb: '/thumb-home-propeasy-vi.png',
    },
    properties_detail: (symbol: string) => ({
      title: `Propeasy | Investment in ${symbol}`,
      description: `Invest in ${symbol} tokens, potential assets provided and guaranteed by Propeasy`,
      thumb: '/thumb-home-propeasy-vi.png',
    }),
    prelaunch: {
      title: 'Propeasy - Invest Easily In Tokens Backed By Real Estates',
      description:
        "Participate in Propeasy's Airdrop event on Renec blockchain with lots of attractive rewards waiting for you!",
      thumb: '/thumb-share-propeasy-en.png',
    },
    terms_of_service: {
      title: 'Propeasy - Terms of service',
      description:
        'These Terms and Conditions govern your access to and use of the Propeasy Site and Services',
      thumb: '/thumb-home-propeasy-en.png',
    },
    privacy_policy: {
      title: 'Propeasy - Privacy policy',
      description: 'This Privacy Policy govern your access to and use of the Propeasy Site',
      thumb: '/thumb-home-propeasy-en.png',
    },
  },
  main_menu: {
    properties: 'Properties',
    document: 'Document',
    blog: 'Blog',
  },
  error: {
    go_back_home: 'Back to home',
    error_404: '404 - Page not found',
    error_message: 'An error occurred, please try again later',
    email_verified: 'Email has been registered, please log in or try again with another email',
    email_not_verified:
      'Your email is not registered, please sign up and verify your email successfully to be eligible to participate in our exclusive Airdrop program.',
    error_captcha_required: 'Captcha is required',
    fetch_burn_proof_error: 'Cannot fetch burn token transactions',
  },
  properties: {
    apy: 'Est. APY',
    business_profit: 'Expected business profit:',
    asset_profit: 'Expected asset price appreciation:',
    detail: 'View details',
    est_open_for_sale: 'Estimated opening date',
    open_for_sale: 'Opening date',
    buy_now: 'Buy now',
    buy_now_subtitle: (tokenNumber: string, tokenPrice: number) =>
      `Available: ${tokenNumber} tokens${tokenPrice ? ` at $${tokenPrice}` : ''}`,
  },
  properties_detail: {
    share: 'Share referral code',
    introduction: 'Introduction',
    location: 'Location',
    kind: 'Asset category',
    size: 'Area',
    price: 'Valuation',
    apy: 'Annual return',
    utility: 'Special utilities',
    legal: 'Legal documents',
    authenticated: 'Renec foundation verified',
    renec_authenticated: (total: number) =>
      `${total} documents below verified by <br/> <strong>RENEC Foundation</strong>`,
    security_token: 'Real-estate token',
    token: 'Token',
    total_supply: 'Total supply',
    token_address: 'Token address',
    open_for_sale: 'Open for Sale',
    more_liquidity: 'Add Liquidity',
    burnt_amount: 'Burnt amount',
    value_growth: 'Value growth engine',
    value_growth_one: 'Periodic token burning with profits from the asset',
    value_growth_one_notice:
      'Propeasy announces monthly business progress from real estate for token holders and commits to using 100% of the business profit to perform token burning.',
    value_growth_two: 'Token buyback at good price when asset is liquidated',
    value_growth_two_notice:
      'When the real estate appreciates sufficiently in the future, Propeasy carries out asset liquidation with a good price for token holders by committing to repurchasing all tokens at an attractive valuation at the time of liquidation.',
    ownership_benefits: 'Ownership benefits',
    ownership_benefits_one:
      'Safe long-term value growth according to the unique value growth engine of the real estate',
    ownership_benefits_two: (token: string, articleUrl?: string) =>
      `PROP rewards with every ${token} purchase transaction from ${
        articleUrl
          ? `<a class="text-ps-secondary underline font-bold" target="_blank" href="${articleUrl}">Referral Commission program</a>`
          : 'Referral Commission program'
      }`,
    ownership_benefits_three: (swapUrl?: string) =>
      `Flexible trading with 24/7 liquidity on <a class="text-ps-secondary underline font-bold" target="_blank" href="${
        swapUrl ? swapUrl : 'https://nemo.renec.org/'
      }">NemoSwap</a>`,
    ownership_benefits_four:
      'Easily collateralize tokens to lend and borrow stable coins on <a class="text-ps-secondary underline font-bold" href="https://lend.renec.org">RENEC Lend</a>',
    sale_info: {
      private_sale: 'Private sale',
      public_sale: 'Public sale',
      price: 'Price',
      promotion: (discount: number) => `Discount ${discount.toLocaleString()}%`,
      qty_for_sale: 'Amount for Sale',
      minimum_purchase: 'Minimum Purchase',
      lock_time: 'Vesting Duration',
      opening_date: 'Sale Date',
      unlock_duration: (weeks: number) => (weeks > 0 ? `${weeks.toFixed()} weeks` : 'None'),
      commission_amount: 'Referral Commission',
      commission_notice: (amount: string) =>
        `Bonus commissions up to ${amount} PROP for both buyers and their referrers`,
      commission_tooltip:
        'Phần thưởng được chia đều cho cả người giới thiệu và tuyến dưới khi tuyến dưới thực hiện mua thành công',
      sold: (sold: number, total: number, token: string) =>
        `Sold: <span class="font-bold text-[#148F00]">${sold.toLocaleString()}</span> / <span class="text-[#8F9397]">${total.toLocaleString()} ${token}</span>`,
      conditions: 'Participation conditions',
      condition_one: (propReq: number, prop: number) =>
        `Have at least ${propReq} PROP in the wallet ${
          prop === 0
            ? '<a class="text-ps-secondary underline" target="_blank" href="https://nemo.renec.org/en/reUSD_PROP">(Buy PROP)</a>'
            : ''
        }`,
      condition_two: (address?: string) =>
        `Địa chỉ ví đã KYC thành công ${
          address
            ? `<br/><a class="text-ps-secondary font-bold underline" href="https://remitano.com/btc/au/verify-ekyc?fromDemonWallet=${address}">KYC ngay</a>`
            : ''
        }`,
      condition_none: 'No requirements. Everyone can participate.',
      qualified: 'You are qualified',
      unqualified: 'You are not qualified',
      lock_duration_notice: (date: string) => `Lock ends on: <strong>${date}</strong>`,
      error: (code: string) => {
        switch (code) {
          case '0x1770':
            return 'The start time must be before the end time'
          case '0x1771':
            return 'The total supply amount of sales must be smaller than or equal to supply of token'
          case '0x1772':
            return 'The purchase time provided is not within the allowed range'
          case '0x1773':
            return 'Exceed supply amount'
          case '0x1774':
            return 'Must have platform token account in private sale'
          case '0x1775':
            return 'Not reach minimum amount'
          case '0x1776':
            return 'Must have enough platform token in private sale'
          case '0x1777':
            return 'Mismatch token account and owner'
          case '0x1778':
            return 'There are no amount to claim'
          case '0x1779':
            return 'Not enough balance to transfer'
          case 'blockhash_not_found':
            return 'Blockhash not found'
          case 'transaction_timeout':
            return 'The transaction time has exceeded the allowed time limit'
          case 'no_record_credit':
            return 'You need to have at least 0.00001 RENEC in your wallet to proceed the transaction successfully'
          default:
            return ''
        }
      },
    },
    purchase: {
      last_buyer_dialog: {
        title: 'Congratulation for receiving',
        reward: (amount: number) => `${amount} USDT`,
        content: (amount: number, token: string) =>
          `You are the last person to buy ${token.toUpperCase()} tokens in this sale and will receive a reward of ${amount} USDT.`+
          ' The amount will be sent to your account within 5 working days. Thank you for participating and investing in our project!',
          ok: 'ok',
      },
      enter_amount: 'Enter amount to buy:',
      warning_amount: 'Please enter the amount',
      max_amount: 'Max',
      receive: 'Receive:',
      locked: 'Locked:',
      commission: 'Your commission:',
      cal_token: (amount: number, token_symbol: string, color: string, prefix?: string) =>
        `${
          !!!amount
            ? '--'
            : `<span class="text-[${color}]">${prefix || ''}${amount.toLocaleString()}</span>`
        } ${token_symbol}`,
      balance: 'Balance:',
      buy_now: 'Buy now',
      summary_transaction: 'Transaction summary',
      property: 'Asset:',
      token: 'Token:',
      price: 'Price:',
      sell_type: 'Sale:',
      amount: 'Buy:',
      bonus: 'Bonus:',
      confirm_sell: 'Confirm purchase',
      transaction_policy:
        'By continuing, you agree to comply with our <a href="/terms-of-service" target="_blank" class="text-ps-secondary"> Terms of Service </a> and all related laws.',
      sent_transaction: 'Transaction Sent to Blockchain',
      sent_transaction_description: (token: string) =>
        `Once your transaction on the RENEC blockchain is successfully validated, the purchased amount of ${token} will appear in your wallet address.`,
      share_referral: 'Share referral code',
      unlimited_commission: 'Unlimited commission income',
      transaction_history: 'View transaction on Explorer',
      sent_transaction_failed: 'Transaction Failed',
      sent_transaction_failed_description: 'Sending transaction failed. Please try again.',
      retry: 'Try again',
      error: {
        zero_amount: 'Please enter an amount',
        minimumPurchase: 'Amount must be greater than or equal to the minimum purchase',
        maximumPurchase: 'Exceeds the allowed purchase quantity',
        insufficient_funds: 'Insufficient funds',
      },
      deposit_usdt: 'View how to deposit USDT',
      deposit_usdt_url: 'https://renec.org/blog/how-to-deposit-usdt-into-carax-wallet',
    },
    asset_type: {
      residential_land: 'Residential land',
      commercial_land: 'Commercial land',
      service_apartment: 'Service apartment',
      townhouse_for_rent: 'Townhouse for rent',
      forest_land_for_commercials: 'Forest land for commercials',
    },
    area_type: {
      meter: 'm²',
    },
    valuation_type: {
      million: 'million',
      billion: 'billion',
    },
    utilities: {
      buy: {
        title: (tokenSymbol: string) => `Buy ${tokenSymbol.toUpperCase()}`,
        desc: (tokenSymbol: string) =>
          `You don't have ${tokenSymbol.toUpperCase()} yet? Start trading anytime with flexible liquidity 24/7.`,
        btn: 'Buy now',
      },
      deposit: {
        title: (tokenSymbol: string) => `Deposit ${tokenSymbol.toUpperCase()}`,
        desc: (tokenSymbol: string) =>
          `Increase your profits by depositing ${tokenSymbol.toUpperCase()} to receive attractive interest rates or borrow cash easily and quickly.`,
        btn: 'Deposit now',
      },
    },
    burn_proof: {
      title: 'Token burn',
      time: 'Time',
      hash: 'Hash',
      amount: 'Burned amount',
    },
  },
  launch: {
    home: {
      subject:
        'Real estate 4.0<br/>Start investing <span class="text-ps-secondary font-bold">from just 50 cents</span> ',
      description:
        'We offer a blockchain technology solution that allows you to easily own and trade tokens backed by real estates that are <span class="text-ps-secondary font-bold">evaluated and supervised by Renec Foundation.</span>',
      sub_description:
        'Subscribe below to get the latest updates on <span class="text-ps-secondary font-bold">the upcoming official release of Propeasy</span> and explore opportunities to utilize the accumulated PROP tokens!',
      coming_soon: 'Coming soon...',
      play_video: 'Play video',
      video_url: 'https://www.youtube.com/embed/a8iU-vldhaI?autoplay=1',
      video_title: 'The Operating Model of Propeasy: The 4.0 real estate investment platform',
      information: {
        card_1: {
          title: 'Flexible Investment Capital',
          description:
            'Buy tokens representing shares of a real estate from just 50 cents, instead of buying the entire asset at high cost in the traditional way.',
        },
        card_2: {
          title: 'Safe Growth',
          description:
            "Real estates can potentially generate steady business revenue and sustainable long-term appreciation, protecting investors' assets as their tokens increase in value accordingly.",
        },
        card_3: {
          title: '24/7 Liquidity',
          description:
            'Buy and sell tokens at any time on exchanges with good liquidity, helping investors access capital immediately when needed.',
        },
        card_4: {
          title: 'Trusted Origin of Real Estates',
          description:
            "Each real estate has information verified, value appraised and carefully monitored by Renec Foundation to protect investors' interests.",
        },
        card_5: {
          title: 'Lifetime Commissions',
          description:
            'Unlimited passive income from commissions up to 40% of trading fees for both referrer and referee.',
        },
        card_6: {
          title: 'Simple Purchase',
          description:
            'Pay with crypto and own tokens instantly in less than 5 minutes with just a few clicks on a simple, safe and convenient interface.',
        },
      },
    },
    register: {
      description:
        'Đăng ký bên dưới để nhận thông báo sớm khi Propeasy ra mắt và tham gia vào chương trình Airdrop với nhiều phần quà hấp dẫn.',
      email: 'Enter your email...',
      register: 'Sign up',
      total_register: (total: number) =>
        `<span class="text-ps-secondary font-bold">${total.toLocaleString(
          'en',
        )}</span> users participated in the airdrop`,
      confirm_title: 'Signup verification needed',
      confirm_description:
        'We sent a verification link to your email. Please check your email and follow the instructions to complete signup verification.',
      confirm_done: 'Understood',
    },
    login: {
      title: 'Enter the email that you signed up',
      confirm_title: 'Signin verification needed',
      confirm_description:
        'We sent a verification link to your email. Please check your email and follow the instructions to complete signin verification.',
      confirm_done: 'Understood',
      account_not_yet: 'Don’t have an account?',
    },
    countdown: {
      title: 'Ends in',
      date_open: 'Opening date',
      day: 'DAY',
      hour: 'HOUR',
      minute: 'MINUTE',
      second: 'SECOND',
    },
    airdrop: {
      subject: 'Airdrop Event of PROP Token for the PROPEASY community',
      description: 'Complete the tasks below to claim lots of PROP and USDT into your wallet!',
      more: 'Read the benefits of PROP token',
      total_register: (total: number) =>
        `<span class="text-ps-dark-blue font-bold">${total.toLocaleString(
          'en',
        )}</span> users participated in the airdrop`,
      about: {
        title: 'About PROP Token',
        confirm_done: 'Understood',
        header:
          'PROP is the native token of Propeasy platform. When holding PROP, you will enjoy the following benefits:',
        details: [
          'Participate in Private Sale at discounted price every time a new token is released',
          'Vote on the platform’s proposals for important development and operation',
          'Trade PROP on cryptocurrency exchanges',
        ],
        footer: 'More details about PROP will be announced when Propeasy officially launches.',
      },
      join_title: 'Successful signup',
      join_description:
        "Thank you for joining the <strong>Propeasy community</strong>, where together we build the future of real estate 4.0.<br /><br />Don't miss the opportunity to participate in the first Airdrop and receive special rewards exclusively for the Propeasy community.",
      join_confirm: 'Join Airdrop',
      congratulate: 'Congratulations, you get',
      amount_received: (value: number) => `<strong>${value}</strong> PROP`,
      survey_confirm: 'Ok',
      ended: 'Ended',
      warning_title: 'Update wallet address to receive your rewards',
      warning_description:
        'The wallet address you previously connected is being connected to many other email accounts. To ensure fairness and transparency for all users, <strong>each email account is only connected to a single wallet address.</strong><br /><br />Please update your wallet address so we can send you rewards within 48 hours after the airdrop campaign ends.',
      warning_suspected_title: 'Unusual behavior detected',
      warning_suspected_description:
        'We detected unusual behavior from your account. This may happen because you or your referees were taking unfair advantage of the airdrop’s referral program. After careful consideration, we have regretfully decided that the rewards will not be distributed to your account.',
      warning_confirm: 'Understood',
    },
    survey: {
      login: 'Sign in',
      login_to_continue: 'Login to continue',
      mission_one: {
        title: 'Task 1',
        topic: 'Survey',
        description: 'Please answer below to help us understand your needs and serve you better.',
        notice: 'Receive up to <strong>10 PROP</strong> into your wallet after completing survey.',
        question_one:
          '<strong>Question 1 (*):</strong> What is the minimum annual percentage yield that you desire when investing in tokens backed by real estates (such as forestry land, commercial land, etc.) that are evaluated and supervised by Renec Foundation?',
        question_two:
          '<strong>Question 2 (*):</strong> If you can achieve the minimum annual percentage yield in question 1, how much capital are you willing to invest for each token sale?',
        question_three:
          '<strong>Question 3:</strong> Do you have any expectations when investing in tokens backed by real estates?',
        question_two_marks: {
          vietnam: [
            {
              value: 0,
              label: '0 dong',
            },
            {
              value: 1000,
              label: '1 billion dong',
            },
          ],
          nigeria: [
            {
              value: 0,
              label: 'NGN 0K',
            },
            {
              value: 30000,
              label: 'NGN 30 millions',
            },
          ],
          default: [
            {
              value: 0,
              label: 'USD 0K',
            },
            {
              value: 1000,
              label: 'USD 1 million',
            },
          ],
        },
        question_two_value_format: (country: string, value: number) => {
          switch (country) {
            case 'nigeria':
              return value < 1000
                ? `${value.toLocaleString()}K`
                : `${(value / 1000).toLocaleString()}M`
            case 'vietnam':
              return value < 1000
                ? `${value.toLocaleString()}${value === 0 ? ' dong' : 'M'}`
                : `${(value / 1000).toLocaleString()}B`
            default:
              return value < 1000
                ? `${value.toLocaleString()}K`
                : `${(value / 1000).toLocaleString()}M`
          }
        },
        potential_profits: (country: string, value: number) => {
          let label = ''
          switch (country) {
            case 'nigeria':
              label =
                'NGN ' +
                (value < 1000
                  ? `${value.toLocaleString()}K`
                  : `${(value / 1000).toLocaleString()}M`)
              break
            case 'vietnam':
              label =
                value < 1000
                  ? `${value.toLocaleString()} millions dong`
                  : `${(value / 1000).toLocaleString()} billion dong`
              break
            default:
              label =
                'USD ' +
                (value < 1000
                  ? `${value.toLocaleString()}K`
                  : `${(value / 1000).toLocaleString()}M`)
              break
          }
          return `Total yearly profit: <strong class="text-lg text-[#3EA40E]">${label}</strong>`
        },
        question_three_placeholder: 'Enter your answer...',
        submit: 'Finish',
        error_message: 'An error occurred, please try again later',
      },
      mission_two: {
        title: 'Task 2',
        topic: 'Share with friends',
        description:
          'Invite friends to sign up for the Airdrop campaign. The more friends that sign up, the more rewards for everyone!',
        notice_one:
          'Receive <strong class="text-ps-secondary">up to 5 PROP</strong> for each referee who successfully signs up with email.',
        notice_two:
          'Receive <strong class="text-ps-secondary">10 USDT (reUSD)</strong> when you are in the top 100 users who has the most successfull referrals.',
        notice_three:
          'Each referee who successfully signs up with email will also receive <strong class="text-ps-secondary">1 PROP</strong> into their wallet.',
        share: {
          title: 'Share your referral code with friends',
          other: 'Or share via',
          referral_success: (total: number) =>
            `<strong>Referred successfully: ${total.toLocaleString()}</strong> Friends`,
          referral_other: (total: number) =>
            `<strong>${total.toLocaleString(
              'en',
            )} other uses</strong> have been referring more friends than you`,
          copied: 'Copied',
        },
      },
      finished: 'Survey completed',
    },
    profile: {
      welcome: 'Hi',
      award: 'Your reward:',
      amount_prop: (value: number) => `<strong>${value}</strong> PROP`,
      amount_reusd: (value: number) => `<strong>${value}</strong> USDT (reUSD)`,
      connect_to_received: 'Connect wallet',
      notice:
        'Rewards will be sent to your wallet by <strong>23:59 PM 11/30/2023 (GMT+7)</strong>.',
      notice_award:
        'Not eligible to receive rewards because you registered an account after <strong>11/15/2023</strong> or connected wallet with KYC after <strong>11/26/2023</strong>.',
      notice_kyc: "You haven't verified your wallet address to receive the reward.",
      continue_kyc: 'KYC now',
      get_award: 'Get reward',
      awarded: 'Reward claimed',
      waiting_for_reward: 'Waiting for reward distribution',
      logout: 'Sign out',
    },
  },
  transactionHistory: {
    title: 'Transaction History',
    all: 'All',
    private: 'Private sale',
    public: 'Public sale',
    claim: 'Claim',
    property: 'Property',
    token: 'Token',
    price: 'Price',
    txType: 'Transaction',
    amount: 'Amount',
    time: 'Time',
    hash: 'Hash',
    statuses: {
      success: 'Success',
      failed: 'Failed',
      pending: 'Confirming',
    },
  },
  purchaseHistory: {
    not_purchase: "You didn't participate in this sale.",
    title: 'Purchase history',
    history: 'View all',
    paid: 'You bought',
    refundedAmount: 'Unlocked',
    lockedAmount: 'Locked',
    lockedAmountNotice: (date: string) => `Lock ends on</br><b>${date}</b>`,
    nextUnlockedDate: 'Next unlock at',
    canUnlockAmount: 'You can claim',
    canUnlock: 'Claim',
    claim_success: (tokenSymbol?: string) =>
      `After your transaction on the Renec blockchain is successfully comfirmed, the unlocked amount ${
        tokenSymbol ? 'of ' + tokenSymbol.toUpperCase() : ''
      } will appear in your wallet address.`,
    claim_failure: 'Transaction sending failed, please try again.',
    close: 'Close',
  },
  blog: {
    categories: {
      all: 'All',
      announcement: 'Announcement',
      event: 'Event',
    },
  },
  blog_detail: {
    published_at: 'Published at',
  },
  survey: {
    multiChoice: 'select multiple',
    submit: 'Submit',
    personalInfo: {
      title: '1. Personal Information',
      desc: 'We are committed to securing your information.',
    },
    surveyInfo: {
      title: '2. GAST Real Estate Token Sale Survey',
      desc: 'Please assist us in evaluating the quality of the recent GAST real estate token sale.',
    },
    ask: {
      title: 'User Feedback Survey on the recent GAST Real Estate Token Sale',
      desc: "Your feedback and sharing will help us evaluate the recent GAST real estate token sale's quality and provide a foundation for further improvement in upcoming sales projects. We are committed to securing your information and will carefully review all feedback.",
      reward:
        "Valuable feedback will be rewarded from <strong>100 to 500 PROP</strong>. We are grateful for your contribution to the platform's growth.",
      btn: 'Do survey',
    },
    complete: {
      title: 'Complete the survey',
      desc_1: 'The survey has been completed. We sincerely appreciate your contribution.',
      desc_2:
        "Propeasy will review the results and notify reward recipients within 4-5 business days following the survey's conclusion.",
      btn: 'OK',
    },
  },
  trustpilot: {
    rateNow: 'Write a review',
    overview: (totalStar: number, totalReview: number) =>
      `${totalStar}/5 based on ${totalReview} reviews`,
  },
}

export default locale
